<template>
   <v-app>
      <v-container fluid fill-height>
         <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
               <v-card class="elevation-12">
                  <v-toolbar color="#e7e5ba">
                     <v-toolbar-title>Registrazione utente per: <span class="blue--text">{{rivenditore && rivenditore.ragioneSociale}}</span></v-toolbar-title>
                  </v-toolbar>
                  <v-card-title v-if="errorMessage" v-html="errorMessage" class="red--text"></v-card-title>
<!--                   <v-card-title v-if="!errorMessage" class="blue--text">{{rivenditore}}</v-card-title> -->
                  <v-card-text>
                     <v-form ref="formLogin" v-model="formLoginFieldsValid" lazy-validation>
                        <v-text-field
                           v-model="first_name"
                           autofocus
                           prepend-icon="mdi-account-outline"
                           name="first_name"
                           label="Nome"
                           type="text"
                           :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                           v-model="last_name"
                           prepend-icon="mdi-account-outline"
                           name="last_name"
                           label="Cognome"
                           type="text"
                           :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                           v-model="email"
                           prepend-icon="mdi-account-outline"
                           name="email"
                           label="Email"
                           type="text"
                           :rules="[rules.required, rules.email]"
                        ></v-text-field>
                        <v-text-field
                           v-model="password"
                           id="password"
                           prepend-icon="mdi-lock-outline"
                           name="password"
                           label="Password"
                           :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                           :rules="[rules.required, rulesL.sameAsConfirmPassword, rulesL.minLength]"
                           :type="showPassword ? 'text' : 'password'"
                           @click:append="showPassword = !showPassword"
                        ></v-text-field>
                        <v-text-field
                           v-model="confirm_password"
                           id="confirm_password"
                           prepend-icon="mdi-lock-outline"
                           name="confirm_password"
                           label="Conferma Password"
                           :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                           :rules="[rules.required, rulesL.sameAsPassword, rulesL.minLength]"
                           :type="showConfirmPassword ? 'text' : 'password'"
                           @click:append="showConfirmPassword = !showConfirmPassword"
                           @keypress="keyDownPwd"
                        ></v-text-field>
                     </v-form>
                  </v-card-text>
                  <v-card-text v-if="userRegisterd" class="text-h5 green--text">La registrazione è stata effettuata correttamente !</v-card-text>
                  <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn v-if="!userRegisterd" color="primary" @click="onRegister">Registrami</v-btn>
                     <v-btn v-if="userRegisterd" color="success" @click="onAccedi">Accedi al sistema</v-btn>
                  </v-card-actions>
               </v-card>
            </v-flex>
         </v-layout>
      </v-container>
   </v-app>
</template>

<script>
import { validazioni } from '@/mixins/validazioni.js'
   import licenze from '@/services/licenzeService'
   import rivenditori from '@/services/rivenditoriService'

   export default {
      name: 'Register',
      mixins: [
         validazioni
      ],
      data () {
         return {
            showPassword: false,
            showConfirmPassword: false,
            first_name: '',
            last_name: '',
            email: '',
            password:'',
            confirm_password: '',  
            rulesL: {
               sameAsPassword: value => !!value && value === this.password || 'Le password non coincidono',
               sameAsConfirmPassword: value => !!value && value === this.confirm_password || !this.confirm_password || 'Le password non coincidono',
               minLength: value => value && (value.length >= 7) || `Almeno 7 caratteri`
            },
            formLoginFieldsValid: true,
            errorMessage: '',
            linkRegistrazione: '',
            rivenditore: null,
            userRegisterd: null
         }
      },
      async mounted() {
         this.linkRegistrazione = this.$route.params.id
         if (!this.linkRegistrazione) {
            this.errorMessage = `E' necessario specificare un codice per la registrazione`
            this.rivenditore = null
         } else {
            const rivenditore = await rivenditori.getRivenditoreFromLinkRegistrazione(this.linkRegistrazione)
            if (!rivenditore) {
               this.errorMessage = `Non è stato trovato un rivenditore associato.<br />La registrazione non è consentita!`
               this.rivenditore = null
            } else {
               this.rivenditore = rivenditore
            }
         }
      },
      methods: {
         async keyDownPwd(e) {
            if (e.charCode === 13) {
               await this.onRegister()
            }
         },
         async onRegister() {
            const valid = this.$refs.formLogin.validate()
            if (valid) {
               const user = await licenze.register({
                  first_name: this.first_name,
                  last_name: this.last_name,
                  email: this.email,
                  password: this.password,
                  company: this.rivenditore.codice,
                  attivo: true
               })
               if (user.error) {
                  this.errorMessage = user.message
               } else {
                  this.userRegisterd = user
               }
            }
         },
         onAccedi() {
            if (this.userRegisterd) {
               this.$store.dispatch('utenti/setUser', this.userRegisterd)
               let redirect = '/'
               this.$router.replace(redirect)
            }
         }
      }
   }
</script>

<style></style>
